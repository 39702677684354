@import "../../../assets/scss/variables.scss";

.nav {
    position: fixed;
    top: 0;
    min-width: 100%;
    width: 100%;
    min-height: $nav-height;
    height: $nav-height;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    user-select: none;
    background-color: $dark-green;
    box-shadow: 0 2px $light-black;
    color: $white;
    z-index: 2;
    overflow: hidden;

    @media only screen and (max-width: $container-md-min-width) {
        justify-content: center;
    }

    .logo {
        padding-right: 1rem;
    }

    .divider {
        width: 0.1rem;
        height: 1rem;
        margin: 0 1rem;
        background: $white-off;
    }

    .logOut {
        margin-left: auto;
    }
}
