@import "../../assets/scss/variables.scss";

$icon-size: 9rem;
$icon-box-shadow: 0 0 2px $black;
$icon-bottom-margin: 1rem;

.checkEmail {
    width: 35%;
    height: 90%;
    padding-bottom: 2rem;

    align-self: center;
    flex-direction: column;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;

    .checkEmailContent {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        align-items: center;
        justify-content: center;

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: $icon-size;
            height: $icon-size;
            padding: 2rem;
            border-radius: $icon-size;
            box-shadow: $soft-shadow;
            background: $green;
            color: $white;
            margin-bottom: $icon-bottom-margin;
        }
    }

    .backToLogin {
        width: 100%;
    }
}

@media only screen and (max-width: $container-md-min-width) {
    .checkEmail {
        width: 85%;
        height: 60%;
        max-width: 310px;
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: auto;
    }
}
