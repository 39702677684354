@import "../../assets/scss/variables.scss";

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 2.5rem;
}

.title {
    margin-bottom: 2rem;
}

.items {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-around;
    width: 100%;
    padding: 2rem 3rem;

    .item {
        display: flex;
        justify-content: space-around;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: center;
        box-shadow: $soft-shadow;
        padding: 1rem;
        border-radius: 0.2rem;

        &:first-child {
            margin-right: 5rem;
        }

        .icons {
            display: flex;
            justify-content: center;
            color: $green;
            width: 100%;

            .plusIcon {
                padding: 0 1rem;
            }
        }

        .button {
            width: 90%;
        }
    }
}
