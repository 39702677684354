@import "../login/login.module.scss";

.container {
    height: 80%;

    .setTitle {
        font-size: 3rem;
    }

    .passwordTitle {
        font-size: 3rem;
    }

    .inputContainer {
        margin-bottom: 8px;
    }

    .inputError {
        height: 16px;
    }

    @media only screen and (max-width: $container-md-min-width) {
        height: 60%;

        .title {
            text-align: center;
        }
    }
}
