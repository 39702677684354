@import "../../../assets/scss/variables.scss";

.logoContainer {
    height: 100%;
    display: flex;
    align-items: center;
    color: $white;
    cursor: pointer;

    .logo {
        height: 60%;
        margin: 0 1.25rem;
    }

    .label {
        white-space: nowrap;
    }
}
