@import "../../assets/scss/variables.scss";

.parentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    background-image: linear-gradient(to bottom right, $white, $white-fade);

    .pageTitle {
        margin: 0 0 2rem 0;
        width: 80%;
    }

    .form {
        width: 80%;
        max-width: $container-md-min-width;
        height: auto;
        display: flex;
        flex-direction: column;
        box-shadow: $soft-shadow;
        border-radius: 0.5rem;
        margin-bottom: 2rem;
        padding: 1rem;
    }

    .updateButton {
        width: 80%;
        min-height: 3rem;
        max-width: $container-md-min-width;
        display: flex;
        justify-content: flex-end;
    }
}
