@import "../../../assets/scss/variables.scss";

.title {
    font-weight: bold;
    font-size: $font-size-title;

    @media only screen and (max-width: $container-md-min-width) {
        font-size: $font-size-title * 0.75;
    }

    @media only screen and (max-width: $container-sm-min-width) {
        font-size: $font-size-title * 0.5;
    }
}

.subtitle {
    font-weight: 500;
    font-size: $font-size-subtitle;

    @media only screen and (max-width: $container-md-min-width) {
        font-size: $font-size-subtitle * 0.75;
    }

    @media only screen and (max-width: $container-sm-min-width) {
        font-size: $font-size-subtitle * 0.5;
    }
}

.heading {
    font-weight: 400;
    font-size: $font-size-heading;

    @media only screen and (max-width: $container-md-min-width) {
        font-size: $font-size-heading * 0.75;
    }

    @media only screen and (max-width: $container-sm-min-width) {
        font-size: $font-size-heading * 0.5;
    }
}

.centered-title {
    @extend .title;
    text-align: center;
}

.float-top {
    margin-bottom: auto;
}

.page-title {
    @extend .title;
    margin: 1rem;
}
