@import "../../assets/scss/variables.scss";

.parentContainer {
    width: 100%;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    padding: 3rem;
    background-image: linear-gradient(to bottom right, $white, $white-fade);

    .stepper {
        margin: 2.5rem 0;
    }

    .content {
        min-width: 36rem;
        width: 75%;
        height: 100%;
        align-self: center;
        margin-top: 1rem;
    }
}
