@import "../../../assets/scss/variables.scss";

.plyr__editor__controls {
    background: $white-off;
    width: 100%;
    box-shadow: $shadow;

    .plyr__editor__controls__zoom__container {
        margin-right: 0.5rem;
        .plyr__control {
            color: $green;
        }
    }
}
