@import "../../../assets/scss/variables.scss";

.heading {
    display: flex;
    align-items: center;
    background: $light-black;
    padding: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    .subtitle {
        font-size: 0.7rem;
        padding-left: 0.1rem;
    }

    .controls {
        display: flex;
        margin-left: auto;
        .button {
            margin: 0;
        }
    }
}
