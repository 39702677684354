@import "../../../assets/scss/variables.scss";

.syncPoints {
    box-shadow: $soft-shadow;
    margin: 0 1rem;
    padding: 0 1rem;
    overflow-y: visible;
    flex: 1 1;

    .controls {
        display: flex;
    }

    .showMore {
        padding: 0 1rem;
    }
}

.editorSave {
    margin-left: auto;
    display: flex;
}
