@use "sass:math";
@import "../../../assets/scss/variables.scss";

$icon-height: 0.75rem;
$progress-height: 0.2rem;
$background: hsl(60, 1%, 86%);
$icon-background: hsl(0, 0%, 75%);

.stepper {
    display: flex;
    align-items: flex-start;

    .step {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        &:after {
            content: "";
            height: $progress-height;
            width: 100%;
            background: $background;
            position: absolute;
            left: 50%;
            bottom: calc(($icon-height - $progress-height) / 2);
        }

        &:last-child {
            &:after {
                display: none;
            }
        }

        .icon {
            width: $icon-height;
            height: $icon-height;
            border-radius: 100%;
            background: $icon-background;
            z-index: 1;

            &.active {
                background: $green;
                box-shadow: 0px 0px 0px 2px $icon-background;
            }
        }

        .label {
            padding: 0 0.5rem 0.5rem 0.5rem;
        }
    }
}
