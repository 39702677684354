@import "../../assets/scss/variables.scss";

$modal-border: 1px solid #cccccc;

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85%;
    padding: 3rem;
}

.fileInput {
    display: none;
}

.title {
    margin-bottom: 2rem;
}

.heading {
    margin-bottom: 0.5rem;
}

.dropZone {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;

    .file {
        height: 4rem;
        display: flex;
        align-items: center;
    }
}

.formGroup {
    flex: 1 1 auto;

    .formFields {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;
        min-width: 12.5rem;
        max-width: 30rem;
        width: 45%;
    }

    .formFields .inputContainer {
        margin-bottom: 24px;
    }
}

.footer {
    background: $light-black;
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2.5rem;

    .cancelButton {
        margin-right: 1rem !important;
    }
}
