@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.fixtureFile {
    min-height: 4rem;
    height: 4rem;
    display: flex;
    position: relative;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 1rem;
    cursor: pointer;

    &.selected {
        background: $light-black;
        border-left: 2px solid $green;
    }

    &:hover,
    &.active {
        transition: 0.5s;
        box-shadow: -6px 3px 6px #f3f2f2;

        .dropdown {
            opacity: 100;
        }
    }

    .fixtureHeading {
        display: flex;
        flex-direction: column;

        .label {
            display: flex;
            align-items: center;
            font-weight: 500;
            @include overflow-ellipsis;
        }

        .uploadedToHudl {
            width: 1.5rem;
            opacity: 0.9;
            margin-left: 0.1rem;
            z-index: 9999;

            @media only screen and (max-width: $container-md-min-width) {
                display: none;
            }

            svg {
                fill: #ed681c;
            }
        }
    }

    .fixtureState {
        margin-left: auto;
    }

    .dropdown {
        opacity: 0;
        right: 0;
        transition: 0s;
        margin: 0.5rem;
        position: absolute;

        .button {
            z-index: 1;
        }
    }

    .rippleContainer {
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        overflow: hidden;
    }
}
