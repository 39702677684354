@import "../../../assets/scss/variables.scss";

$padding: 0.5rem;

.parentContainer {
    width: 37.5rem;
    max-width: 100%;
    max-height: 15rem;
    z-index: 999;
    bottom: 2rem;
    right: 2rem;
    border-radius: 0.5rem;
    position: absolute;
    background: $white;
    box-shadow: $soft-shadow;

    .childContainer {
        display: flex;
        flex-direction: column;
        height: inherit;
    }
}

.collapsed {
    &.parentContainer {
        width: 20rem;
    }
}

@media only screen and (max-width: $container-md-min-width) {
    .parentContainer {
        right: 2.5%;
        bottom: 2.5%;
        width: 95%;
    }
}
