@import "../../assets/scss/variables.scss";

.form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .formGroup {
        display: flex;
        flex-direction: column;
        align-self: center;
        flex: 1 1 auto;
        .heading {
            padding-bottom: 1.5rem;
        }

        .formFields {
            min-width: 12.5rem;
            width: 17.5rem;
            align-self: center;
        }
    }
}
