@import "../../../assets/scss/variables.scss";

.parentContainer {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;

    &:before {
        background-image: linear-gradient(to bottom right, $white, $white-fade);
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
    }
}

.childContainer {
    flex: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 100%;
    padding-top: $nav-height;

    @media only screen and (max-width: $container-md-min-width) {
        padding-top: 0;
    }
}

.content {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;

    @media only screen and (max-width: $container-md-min-width) {
        padding-bottom: $nav-height;
    }
}

.nav {
    @media only screen and (max-width: $container-md-min-width) {
        top: auto;
        bottom: 0;
    }
}
