@import "../../../assets/scss/variables.scss";

$plyr-color-main: var(--plyr-color-main, $green);
$plyr-editor-padding: var(--plyr-editor-padding, 50px);
$plyr-editor-controls-height: var(--plyr-editor-controls-height, 65px);
$plyr-editor-controls-margin: var(--plyr-editor-controls-margin, 10px);

$plyr-editor-controls-box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.16);

@import "~@sbgsportssoftware/plyr/src/sass/plyr.scss";

.playerContainer {
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    min-height: 0;
    min-width: 0;
}

.plyr--video {
    border-radius: 0.25rem;
    box-shadow: $shadow;
}

.plyr__editor__container {
    margin: 30px 0;
}

.plyr__editor__controls {
    margin-left: calc(#{$plyr-editor-padding} * -1);
    width: calc(100% + #{$plyr-editor-padding} * 2);
    margin-top: $plyr-editor-controls-margin;
    padding: 10px 30px;
    box-shadow: $plyr-editor-controls-box-shadow;
}

.plyr__editor__controls__zoom__container {
    margin-left: 0;
}

.plyr__controls .plyr__controls__item.plyr__time.plyr--match-time {
    min-width: 4.75rem;
}

.plyr__editor__controls .plyr__editor__controls__time-container {
    min-width: 5rem;
}
