.ripple-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .ripple {
        position: absolute;
        transform: scale(0);
        border-radius: 100%;
        opacity: 0.75;
        animation: ripple 0.5s linear;

        &.black {
            background-color: rgba(0, 0, 0, 0.3);
        }

        &.white {
            background-color: rgba(255, 255, 255, 0.3);
        }
    }

    @keyframes ripple {
        to {
            opacity: 0;
            transform: scale(2);
        }
    }
}
