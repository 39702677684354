@import "../../../assets/scss/variables.scss";
@import "~react-toastify/dist/ReactToastify.min.css";

.Toastify__toast {
    min-height: 3.5rem;
    font-family: inherit;
    border-radius: 0.2rem;
    text-transform: uppercase;
    font-size: 0.8rem;

    &-container--top-right {
        top: 4em;
    }
}

.Toastify__toast--success {
    background: $dark-green;
}

.Toastify__toast--error {
    background: $error;
}
