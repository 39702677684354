@import "../addUser/addUserForm.module.scss";

.form {
    @extend .form;

    .formGroup {
        width: 80%;
        min-width: $container-sm-min-width;

        .formFields {
            width: inherit;
            display: flex;

            .formField {
                margin-bottom: 24px;
            }

            input {
                flex: 1 1 auto;
            }
        }

        .addRow {
            margin: 0;
            padding: 0.5rem 0;
            border-radius: 0.25rem;
        }
    }

    .adminPrivileges {
        opacity: 40%;
    }

    .adminPrivilegesInfo {
        margin-top: 2.5rem;
    }
}
