@import "../../assets/scss/variables.scss";

.container {
    width: 35%;
    height: 87%;
    margin-right: 4.75rem;

    align-self: flex-end;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;

    .title {
        line-height: 1;
        text-align: left;
        margin-bottom: auto;
        width: 100%;
    }

    .inputGroup {
        width: 100%;

        .inputContainer {
            margin-bottom: 8px;
        }

        .inputError {
            height: 16px;
        }
    }

    .submit {
        width: 100%;
    }

    .forgotPassword {
        width: 100%;
        padding: 0 1.5rem;
        margin: 0;
    }

    @media only screen and (max-width: $container-md-min-width) {
        width: 85%;
        height: 60%;
        max-width: 310px;
        margin-top: 1.5rem;
        margin-left: auto;
        margin-right: auto;

        .title {
            text-align: center;
        }
    }
}
