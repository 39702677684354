@import "../../assets/scss/variables.scss";

.fixturesContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    background-image: linear-gradient(to bottom right, $white, $white-fade);

    @media only screen and (max-width: $container-md-min-width) {
        padding: 1rem;
    }

    .pageTitle {
        width: 85%;
        .title {
            font-size: 4.25rem;
        }
    }

    .fixturesList {
        width: 100%;
        height: auto;
        margin: 1rem;

        @media only screen and (max-width: $container-md-min-width) {
            margin: 0;
        }
    }

    .filterControls {
        display: none;

        @media only screen and (max-width: $container-lg-min-width) {
            display: flex;
            justify-content: flex-end;
            min-height: 3rem;

            .filterButton {
                padding: 0.6rem 1.4rem;
                color: $green;
            }
        }
    }

    .spinner {
        align-self: center;
    }
}
