@import "../../assets/scss/variables.scss";

$title-height: 100px;

.competitionSummaryContainer {
    width: 100%;
    min-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 3rem;
    background-image: linear-gradient(to bottom right, $white, $white-fade);

    @media only screen and (max-width: $container-md-min-width) {
        padding: 1rem;
        font-size: 0.7rem;
    }

    .dashboardTitle {
        font-weight: bold;
        height: 6.25rem;
        font-size: $font-size-title;
        margin: 1rem;

        @media only screen and (max-width: $container-md-min-width) {
            font-size: $font-size-title * 0.6;
            height: calc(#{$title-height} * 0.6);
        }

        @media only screen and (max-width: $container-sm-min-width) {
            font-size: $font-size-title * 0.45;
            height: calc(#{$title-height} * 0.45);
        }
    }

    .lastUpdated {
        padding-left: $last-updated-space;
        padding-bottom: $last-updated-space;
        color: $last-updated-color;
        font-size: $last-updated-font-size;
    }

    .fixturesList {
        height: auto;

        @media only screen and (max-width: $container-md-min-width) {
            margin: 0;
        }
    }

    .filterControls {
        display: none;

        @media only screen and (max-width: $container-xl-min-width) {
            display: flex;
            justify-content: flex-end;
            min-height: 3rem;

            .filterButton {
                padding: 0.6rem 1.4rem;
                color: $green;
            }
        }
    }

    .spinner {
        padding: 2rem;
        align-self: center;
    }
}
