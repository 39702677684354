@import "../../assets/scss/variables.scss";

$modal-border: 1px solid #cccccc;

.modal {
    width: 90%;
    max-width: $container-lg-min-width;
    background: $white;
    flex-direction: column;
    position: fixed;
    overflow: visible;
    outline: none;
    border: $modal-border;
    border-radius: 0.25rem;
    top: 0;
    left: 0;
    transform: translate(calc(50vw - 50%), calc(50vh - 50%));
}
