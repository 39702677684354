@import "../../../assets/scss/variables.scss";

.row {
    border-bottom: 1px solid #efefef;
    height: 3rem;
    text-align: center;
}

.attributes {
    text-align: right;
}

.checkIcon {
    color: $green;
}
