@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

$shadow: 2px 0 11px #151738;

.fixtureContainer {
    width: 100%;
    min-height: 3.75rem;
    height: auto;
    max-width: 1300px;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #efefef;
    cursor: pointer;

    @media only screen and (max-width: $container-lg-min-width) {
        flex-direction: column;
        min-height: 9rem;
    }

    .childContainer {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        align-items: center;
    }

    &:hover,
    &.active {
        transition: 0.5s;
        box-shadow: -6px 3px 6px #f3f2f2;

        .dropdown {
            opacity: 100;
        }
    }

    .column {
        @include overflow-ellipsis;

        @media only screen and (max-width: $container-lg-min-width) {
            width: 100% !important;
        }
    }

    .date {
        @extend .column;
        min-width: $fixture-angle-column-date-width;
        width: $fixture-angle-column-date-width;
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: $container-lg-min-width) {
            align-items: center;
        }
    }

    .competition {
        font-size: 0.7rem;
        @include overflow-ellipsis;
    }

    .fixtureStateContainer {
        @extend .column;
        min-width: $fixture-angle-column-state-width;
        width: $fixture-angle-column-state-width;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;

        @media only screen and (max-width: $container-lg-min-width) {
            padding-top: 1rem;
            flex-direction: column;
        }

        .fixtureState {
            @media only screen and (max-width: $container-lg-min-width) {
                padding-top: 0.5rem;
            }
        }
    }

    .fixture {
        @extend .column;
        min-width: $fixture-angle-column-fixture-width;
        width: $fixture-angle-column-fixture-width;
        display: flex;
        justify-content: center;

        @media only screen and (max-width: $container-lg-min-width) {
            font-size: 1rem;
            padding-top: 1rem;
        }

        .team {
            width: 45%;
            @include overflow-ellipsis;

            &:first-child {
                text-align: right;
            }

            &:last-child {
                text-align: left;
            }
        }

        .result {
            width: 10%;
            font-weight: bold;
            text-align: center;

            @media only screen and (max-width: $container-lg-min-width) {
                display: flex;
                justify-content: center;
                padding: 0 2rem;
            }
        }
    }

    .dropdown {
        position: absolute;
        opacity: 0;
        transition: 0s;
        right: 0;

        .button {
            z-index: 1;
        }
    }
}
