@import "../../assets/scss/variables.scss";

.parentContainer {
    width: 100%;
    height: 100%;

    .childContainer {
        width: 100%;
        height: 100%;
        min-width: $container-sm-min-width;
        display: flex;
        flex-direction: column;
    }
}
