@import "../../assets/scss/variables.scss";

.parentContainer {
    width: 100%;
    padding: 0.84rem 0;
    display: inline-flex;

    .icon {
        display: flex;
        align-items: center;
        color: $green;
        padding-right: 1rem;
    }

    .items {
        flex: 1 1 auto;
        display: inline-flex;
        min-width: 0;

        .fileName {
            padding-right: 5rem;
            align-self: center;
            text-overflow: ellipsis;
            overflow: hidden;

            @media only screen and (max-width: $container-md-min-width) {
                padding-right: 0;
            }
        }

        .fileSize {
            align-self: center;
            padding-right: 1rem;

            @media only screen and (max-width: $container-md-min-width) {
                display: none;
            }
        }
    }

    .deleteButton {
        margin-left: auto;
    }
}
