@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.competitionContainer {
    width: 100%;
    min-height: 3.75rem;
    height: 3.75rem;
    max-width: 1300px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #efefef;
    cursor: pointer;

    @media only screen and (max-width: $container-md-min-width) {
        padding: 1rem;
        text-align: center;
        min-height: auto;
        height: auto;
    }

    .childContainer {
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        padding-left: 1rem;
        padding-right: 1rem;
        justify-content: space-between;
        align-items: center;

        &.disabled {
            cursor: default;
        }

        @media only screen and (max-width: $container-md-min-width) {
            flex-direction: column;
        }

        .iconContainer {
            width: $dashboard-total-state-width;
            display: flex;
            flex-direction: row;

            @media only screen and (max-width: $container-md-min-width) {
                width: 100%;
            }
        }

        &:hover,
        &.active {
            transition: 0.5s;
            box-shadow: -6px 3px 6px #f3f2f2;

            .dropdown {
                opacity: 100;
            }
        }
    }

    .competition {
        min-width: $dashboard-column-league-width;
        width: $dashboard-column-league-width;
        font-size: 1rem;

        @media only screen and (max-width: $container-md-min-width) {
            width: 100%;
            font-size: 0.7rem;
        }
    }

    .filterControls {
        display: none;

        @media only screen and (max-width: $container-xl-min-width) {
            display: flex;
            justify-content: flex-end;
            min-height: 3rem;

            .filterButton {
                padding: 0.6rem 1.4rem;
                color: $green;
            }
        }
    }

    .available {
        @include make-state($green);
    }

    .expected {
        @include make-state($expected);
    }

    .markup {
        @include make-state($markup);
    }

    .notExpected {
        @include make-state($waiting);
    }

    .error {
        @include make-state($error);
    }
}
