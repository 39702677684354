@import "../addUser/addUserForm.module.scss";

.form {
    @extend .form;

    .formGroup {
        width: 60%;
    }

    .formGroup .inputContainer {
        margin-bottom: 24px;
    }
}
