@import "../../assets/scss/variables.scss";

.formGroup {
    width: 100%;
    min-height: 0;
    height: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $light-black;

    .heading {
        padding-bottom: 1.5rem;
    }

    .formFields {
        min-width: 12.5rem;
        width: 17.5rem;
        align-self: center;
    }

    .formFields .inputContainer {
        margin-bottom: 24px;
    }
}
