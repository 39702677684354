@import "../../assets/scss/variables.scss";

$size: 0.875em;

.fixtureState {
    font-family: inherit;
    line-height: inherit;
    min-width: 9rem;
    width: auto;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
    font-size: $size;

    @media only screen and (max-width: $container-lg-min-width) {
        justify-content: center;
        min-width: 1rem;
    }

    .icon {
        width: calc(#{$size} * 0.9);
        height: calc(#{$size} * 0.9);
        border-radius: calc(#{$size} * 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5em;
        background: $waiting;

        &.error {
            background: $error;
        }

        &.cancelled {
            background: $cancelled;
        }

        &.markup {
            background: $markup;
        }

        &.available {
            background: $green;
        }
    }

    .label {
        margin-right: 0.25em;

        @media only screen and (max-width: $container-lg-min-width) {
            display: none;
        }
    }

    .endAdornment {
        @media only screen and (max-width: $container-lg-min-width) {
            display: none;
        }
    }
}
