@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

$padding: 0.5rem;

.content {
    padding: $padding;
    overflow-y: auto;
    flex: 1 1 auto;

    .item {
        padding: $padding;

        .itemDetails {
            display: flex;

            div {
                @include overflow-ellipsis;
            }

            .name {
                flex: 1 1 auto;
            }

            .percentage {
                min-width: 8rem;
                text-align: center;
            }

            .secondsLeft {
                min-width: 9rem;
                text-align: center;
            }
        }
    }

    .progressBar {
        position: relative;
        margin: 0.5rem 0 0.5rem 0;
    }
}

.collapsed {
    display: none;
}
