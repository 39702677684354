@import "../../../assets/scss/variables.scss";
@import "../../../assets/scss/mixins.scss";

$btn-padding-y-small: 0.5rem !default;
$btn-padding-x-small: 1.6rem !default;
$btn-font-size-small: 0.64rem !default;
$btn-outline-padding-y-small: 0.38rem !default;

$btn-padding-x-default: 2.14rem !default;
$btn-padding-y-default: 0.84rem !default;
$btn-margin-default: 0.375rem !default;
$btn-font-size-default: 0.81rem !default;
$btn-outline-padding-y-default: 0.7rem !default;

$btn-padding-y-large: 1rem !default;
$btn-padding-x-large: 2.4rem !default;
$btn-outline-padding-y-large: 0.88rem !default;
$btn-font-size-large: 0.94rem !default;

$btn-icon-margin: 0.5rem !default;
$btn-transition: all 0.2s ease-in-out !default;

$btn-templates: (
    "black": $black,
    "green": $green,
    "white": $white
);

@mixin make-button($name, $color) {
    .btn-#{$name} {
        color: set-color($color);
        background-color: $color !important;

        &:hover {
            color: set-color($color);
            background-color: lighten($color, 7%) !important;
        }

        &:focus,
        &.focus {
            box-shadow: $shadow-hover;
        }

        &:focus,
        &:active,
        &.active {
            background-color: darken($color, 20%);
        }

        &.dropdown-toggle {
            background-color: $color !important;

            &:hover,
            &:focus {
                background-color: lighten($color, 5%) !important;
            }
        }

        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled).active,
        .show > &.dropdown-toggle {
            background-color: darken($color, 20%) !important;
            box-shadow: $shadow-hover;
        }

        &:not([disabled]):not(.disabled):active:focus,
        &:not([disabled]):not(.disabled).active:focus,
        .show > &.dropdown-toggle:focus {
            box-shadow: $shadow-hover;
        }
    }

    .#{$name}-ic {
        color: $color !important;

        &:hover,
        &:focus {
            color: $color;
        }
    }

    a.btn:not([href]):not([tabindex]),
    a.btn:not([href]):not([tabindex]):focus,
    a.btn:not([href]):not([tabindex]):hover {
        color: set-color($color);
    }
    table {
        &.table {
            a {
                &.btn {
                    &.btn-#{$name} {
                        color: set-color($color);
                    }
                }
            }
        }
    }
}

@mixin make-outline-button($name, $color) {
    .btn-outline-#{$name} {
        color: $color !important;
        background-color: transparent !important;
        border: 2px solid $color !important;

        &:hover,
        &:focus,
        &:active,
        &:active:focus,
        &.active {
            color: $color !important;
            background-color: transparent !important;
            border-color: $color !important;
        }

        &:not([disabled]):not(.disabled):active,
        &:not([disabled]):not(.disabled).active,
        .show > &.dropdown-toggle {
            background-color: transparent !important;
            border-color: $color !important;
            box-shadow: $shadow-hover;
        }

        &:not([disabled]):not(.disabled):active:focus,
        &:not([disabled]):not(.disabled).active:focus,
        .show > &.dropdown-toggle:focus {
            box-shadow: $shadow-hover;
        }
    }
}

@mixin button-size($padding-y, $padding-x, $font-size) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
}

@each $btn_name, $color_value in $btn-templates {
    @include make-button($btn_name, $color_value);
    @include make-outline-button($btn_name, $color_value);
}

.btn {
    font-family: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $btn-margin-default;
    text-transform: uppercase;
    word-wrap: break-word;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    border: 0;
    border-radius: $border-radius-default;
    box-shadow: $shadow;
    transition: $btn-transition;
    @include button-size($btn-padding-y-default, $btn-padding-x-default, $btn-font-size-default);
    @include hover-focus-active {
        outline: 0;
        box-shadow: $shadow-hover;
    }

    &.btn-block {
        margin: inherit;
    }

    .icon {
        display: flex;

        &.right {
            margin-left: $btn-icon-margin;
        }
        &.left {
            margin-right: $btn-icon-margin;
        }
    }

    &.btn-lg {
        @include button-size($btn-padding-y-large, $btn-padding-x-large, $btn-font-size-large);
    }
    &.btn-md {
        @include button-size($btn-padding-y-default, $btn-padding-x-default, $btn-font-size-default);
    }
    &.btn-sm {
        @include button-size($btn-padding-y-small, $btn-padding-x-small, $btn-font-size-small);
    }

    &.btn-circle-sm {
        border-radius: 1.5rem;
        width: 1.5rem;
        height: 1.5rem;
        padding: 0rem;
    }

    &.btn-circle-md {
        border-radius: 3rem;
        width: 3rem;
        height: 3rem;
        padding: 0rem;
    }

    &.btn-rounded-sm {
        border-radius: 1rem;
    }

    &.disabled,
    &:disabled {
        @include hover-focus-active {
            box-shadow: $shadow;
        }
        pointer-events: none !important;
        opacity: 0.65;
    }

    &[class*="btn-outline-"] {
        padding-top: $btn-outline-padding-y-default;
        padding-bottom: $btn-outline-padding-y-default;
        &.btn-lg {
            padding-top: $btn-outline-padding-y-large;
            padding-bottom: $btn-outline-padding-y-large;
        }
        &.btn-md {
            padding-top: $btn-outline-padding-y-default;
            padding-bottom: $btn-outline-padding-y-default;
        }
        &.btn-sm {
            padding-top: $btn-outline-padding-y-small;
            padding-bottom: $btn-outline-padding-y-small;
        }

        &.btn-bg-white {
            background: $white !important;
        }
    }

    &.btn-transparent {
        background: transparent !important;
        color: rgba(0, 0, 0, 0.54);
        box-shadow: none;

        &:hover {
            background-color: rgba(0, 0, 0, 0.04) !important;
        }
    }

    svg {
        fill: currentColor;
    }
}
