@import "../../../assets/scss/variables.scss";

.container {
    width: 100%;
    height: 0.2rem;
    overflow: hidden;
    position: fixed;
    z-index: 999;

    .line {
        position: absolute;
        opacity: 0.4;
        background: $dark-green;
        width: 150%;
        height: 100%;
    }

    .subline {
        position: absolute;
        background: $dark-green;
        height: 100%;
    }

    .inc {
        @extend .subline;
        animation: increase 2s infinite;
    }

    .dec {
        @extend .subline;
        animation: decrease 2s 0.5s infinite;
    }

    .percentage {
        @extend .subline;
        transition: width 1.5s;
    }

    @keyframes increase {
        from {
            left: -5%;
            width: 5%;
        }
        to {
            left: 130%;
            width: 100%;
        }
    }
    @keyframes decrease {
        from {
            left: -80%;
            width: 80%;
        }
        to {
            left: 110%;
            width: 10%;
        }
    }
}
