@import "../../../assets/scss/variables.scss";

.info {
    display: inline-flex;
    font-family: inherit;
    font-size: 0.8rem;
    margin-bottom: 0;
    color: $customer-admin;

    .icon {
        padding-right: 0.5rem;
    }
}
