@import "../addUser/addUserForm.module.scss";
@import "../editUser/editUserForm.module.scss";

.formGroup {
    border: 0;

    .formFields {
        display: flex;
        width: 100%;
        flex: 1 1 auto;

        input {
            flex: 1 1 auto;
        }
    }

    .addRow {
        margin: 0;
        padding: 0.5rem 0;
        border-radius: 0.25rem;
    }
}

.adminPrivileges {
    opacity: 40%;
}
