@import "../../assets/scss/variables.scss";

.parentContainer {
    display: flex;
    width: 100%;
    flex: 1 1 auto;
    background: $white;
    padding-top: $player-container-top-padding;

    @media only screen and (max-width: $container-md-min-width) {
        flex-direction: column;
        padding: 1rem;
    }

    .childContainer {
        display: flex;
        justify-content: center;
        min-width: 0;
        width: 100%;
        height: auto;

        @media only screen and (max-width: $container-md-min-width) {
            min-height: calc(100vw * 0.5625 + #{$player-description-height});
        }

        .content {
            display: flex;
            flex-direction: column;
            width: 80%;
            height: 100%;
            max-width: 1250px;
            max-height: 100%;
            gap: 0.5rem;
            margin-bottom: 1.5rem;
            .bottomContent {
                display: flex;
                flex-direction: column;
                .officials {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: 100%;
                    height: 75px;
                    gap: 0.5rem;
                    margin-top: 0.5rem;
                    overflow-y: auto;
                    .official {
                        display: flex;
                        flex-direction: column;
                        //padding-top: 0.38rem;
                        //padding-bottom: 0.38rem;
                        .position {
                            font-weight: bold;
                            padding: 0px;
                        }
                        .name {
                            padding: 0px;
                        }
                    }
                }
            }
            @media only screen and (max-width: $container-md-min-width) {
                width: 100%;
                min-height: calc(100vw * 0.5625 + #{$player-description-height});
            }
        }
    }
}
