@import "../../../assets/scss/variables.scss";

$checkbox-size: 20px;

.checkbox-container {
    position: relative;
    display: inline-block;
    margin: 0px 15px 0px 15px;
    line-height: $checkbox-size;

    label {
        padding-left: $checkbox-size + 7px;
    }

    .checkbox {
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 1;

        /* Checkbox */
        & + label:before {
            width: $checkbox-size;
            height: $checkbox-size;
            background: transparent;
            position: absolute;
            border: 2px solid $green;
            border-radius: 3px;
            content: "";
            left: 0;
            top: 0;
        }

        /* Tick */
        & + label:after {
            position: absolute;
            display: block;
            width: 4px;
            height: 9px;
            top: 4.5px;
            left: 8px;
            content: "";
            border-right: 1.5px solid transparent;
            border-bottom: 1.5px solid transparent;
            transition: 100ms ease-in-out all;
            transform: rotate(45deg);
        }
    }

    :checked + label:after {
        display: inline;
        border-color: $green;
    }

    :hover + label:before {
        border: 2px solid;
    }

    label:hover {
        color: $selected;
        transition: 100ms ease-in-out all;
    }
}
