@import "../../../assets/scss/variables.scss";

.navItemContainer {
    display: flex;
    position: relative;
    align-items: center;
    height: 100%;
    margin: 0 1rem;
    cursor: pointer;

    &.selected {
        border-top: 0.25rem solid transparent;
        border-bottom: 0.25rem solid $nav-selected;
    }

    .navItem {
        display: flex;
        align-items: center;
        height: 2rem;
        padding: 0 0.5rem;
        border-radius: 0.5rem;
        overflow: hidden;
        position: relative;

        .label {
            white-space: nowrap;
        }

        &:hover {
            background-color: $nav-hover;
        }
    }
}
