@import "../../assets/scss/variables.scss";

$modal-border: 1px solid #cccccc;

.title {
    margin-bottom: 0.5rem;
}

.info {
    margin-bottom: 2rem;
}

.item {
    display: flex;
    align-items: center;
    margin-top: 2.5rem;
    box-shadow: 0px 0px 3px #ecececcc;
    border-radius: 0.75rem;
    padding: 1rem;
}

.heading {
    min-width: 7rem;
    font-size: 1.2rem;
}

.container {
    padding: 2.5rem;
}

.content {
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    width: 100%;
    height: 100%;
}

.dropZone {
    width: 55%;
    height: 3.75rem;
    margin-left: auto;
}

.file {
    height: 4rem;
    display: flex;
    align-items: center;
    margin-left: auto;
}

.footer {
    background: $light-black;
    margin-top: auto;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 2.5rem;

    .cancelButton {
        margin-right: 1rem !important;
    }
}
