@import "../../assets/scss/variables.scss";

.usersListContainer {
    width: 100%;
    flex: 1 1 auto;
    padding: 3rem;
    background-image: linear-gradient(to bottom right, $white, $white-fade);

    @media only screen and (max-width: $container-md-min-width) {
        padding: 1rem 1rem $nav-height 1rem;
    }

    .usersListHeader {
        display: flex;
        max-width: 1200px;
        min-height: 10rem;

        @media only screen and (max-width: $container-md-min-width) {
            flex-direction: column;
            min-height: 12.5rem;
        }

        .pageTitle {
            flex: 1 1 auto;

            @media only screen and (max-width: $container-md-min-width) {
                flex: none;
            }

            .title {
                font-size: 4.25rem;
            }
        }

        .actions {
            display: flex;
            align-items: flex-end;
        }
    }

    .usersListChildContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        margin: 1rem 1rem 1rem 1.5rem;

        @media only screen and (max-width: $container-md-min-width) {
            margin: 0;
        }
    }

    .spinner {
        display: flex;
        justify-content: center;
    }
}
