@import "../../assets/scss/mixins.scss";

.userContainer {
    width: 100%;
    min-height: 3.75rem;
    height: 3.75rem;
    max-width: 1200px;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #efefef;
    position: relative;

    &:hover,
    &.active {
        .dropdown {
            opacity: 100;
        }
    }

    .username {
        width: 50%;
        max-width: 25rem;
        @include overflow-ellipsis;
    }

    .dropdown {
        width: auto;
        margin-left: auto;
        opacity: 0;
        transition: 0s;
    }
}
