.actionButton {
    position: relative;

    .actions {
        position: absolute;
        display: flex;
        flex-direction: row;
        top: 100%;
        z-index: 4;
    }
}
