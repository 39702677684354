@import "./variables.scss";

@function set-color($color) {
    @if (lightness($color) > 80) {
        @return $black;
    } @else {
        @return $white;
    }
}

@mixin hover-focus-active {
    &:hover,
    &:focus,
    &:active {
        @content;
    }
}

@mixin overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin make-state($color) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: $dashboard-column-state-width;
    width: $dashboard-column-state-width;
    padding: 0.5rem;

    @media only screen and (max-width: $container-md-min-width) {
        justify-content: center;
    }

    .icon {
        padding: 0.5rem;
        color: $color;
    }

    .number {
        padding-top: 0.6rem;
        padding-bottom: 0.5rem;
    }
}
