@import "../../../assets/scss/variables.scss";

.dropdown {
    position: relative;
    width: auto;

    input {
        cursor: pointer;
    }

    .suggestions {
        display: none;
        flex-direction: column;
        align-items: baseline;
        list-style-type: none;
        position: absolute;
        overflow-y: auto;
        background: $white;
        min-width: 10rem;
        max-height: 12.5rem;
        width: auto;
        white-space: nowrap;
        right: 0;
        z-index: 100;
        padding: 0rem;
        margin-top: -0.5rem;
        border-radius: 0.2rem;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);

        &.active {
            display: flex;
        }

        li {
            display: flex;
            align-items: center;
            cursor: pointer;
            width: 100%;
            padding: 0.5rem 1rem 0.5rem 1rem;
            height: 3rem;
            &:hover {
                background-color: #eee;
            }
        }
    }
}
