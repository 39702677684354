@import "../../assets/scss/variables.scss";

.parentContainer {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 0;
    height: 100%;
    min-width: $container-sm-min-width;

    .childContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .editorContainer {
            width: 100%;
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 0;
            min-width: 0;
            margin-top: $player-container-top-padding;
        }
    }
}
