@import "../../assets/scss/variables.scss";

$size: 0.875em;

.fixtureState {
    font-family: inherit;
    line-height: inherit;
    min-width: 9rem;
    width: 120px;
    padding-left: calc(0.875em);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    transition: all 0.2s ease-in-out;
    font-size: $size;

    @media only screen and (max-width: $container-lg-min-width) {
        min-width: 1rem;
        justify-content: center;
    }

    .icon {
        width: calc(#{$size} * 0.9);
        height: calc(#{$size} * 0.9);
        border-radius: calc(#{$size} * 0.9);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 0.5em;
        background: $waiting;

        &.notexpected {
            background: $waiting;
        }

        &.error {
            background: $error;
        }

        &.markup {
            background: #f8d030;
        }

        &.expected {
            background: #f08030;
        }

        &.available {
            background: $green;
        }

        &.ok {
            background: $green;
        }
    }

    .label {
        margin-right: 0.25em;
    }
}
