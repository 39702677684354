@import "../../assets/scss/variables.scss";

$shadow: 2px 0 2px 0px #ede9e9bf;

/* Browser */
.parentContainer {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 21rem;
    min-width: 21rem;
    height: 100%;
    z-index: 1;
    padding: 1rem;
    padding-top: 4rem;
    -moz-box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;
    background-image: linear-gradient(to bottom right, $white-off, $white-off-fade);

    .childContainer {
        padding-top: 1rem;

        .subtitle {
            font-weight: 400;
            padding-left: 1rem;
            padding-bottom: 3rem;
        }

        .childContent {
            display: flex;
            flex-direction: column;
            margin-left: auto;
            margin-right: auto;
            flex-grow: 1;
            white-space: nowrap;
            padding: 1rem;

            & > div {
                margin-bottom: 40px;
            }
        }
    }

    &.collapsed {
        width: 2.5rem;
        min-width: 2.5rem;

        .childContainer {
            display: none;
        }
    }

    .collapseBtn {
        right: 0;
        z-index: -1;
        position: absolute;
        margin-right: -1.9rem;

        img {
            position: absolute;
            margin-left: -9.85rem;
            margin-top: -6.7rem;
        }
    }
}

/* Mobile */

.overlay {
    display: none;

    &.open {
        display: flex;
    }

    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: hsla(0, 0%, 0%, 0.4) !important;
    position: absolute;
    z-index: 999;

    .parentContainer {
        width: 100%;
        height: 100%;
        max-height: 40rem;
        background: $white;
        padding: 1rem;
        border-top-right-radius: 1rem;
        border-top-left-radius: 1rem;
        display: flex;
        justify-content: center;
        align-self: flex-end;
        overflow: hidden;

        .childContainer {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;

            .subtitle {
                font-size: 1.5rem;
                font-weight: 500;
                padding: 1rem;
            }

            .parentContent {
                display: flex;
                align-content: center;
                flex-direction: column;
                justify-content: center;
                flex: 1 1 auto;

                .childContent {
                    align-self: center;
                    width: 18rem;
                }
            }
        }

        .confirmation {
            display: flex;
            margin-top: auto;
            justify-content: flex-end;
            width: 100%;
            padding: 1rem;

            .button {
                margin: 0 0 0 1rem;
            }
        }
    }
}
