@import "../../assets/scss/variables.scss";

$shadow: 2px 0 11px #151738;

.fixtureHeaderContainer {
    width: 100%;
    min-height: 3.5rem;
    height: 3.5rem;
    max-width: 1300px;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #efefef;
    white-space: nowrap;
    color: $font-color-soft;

    @media only screen and (max-width: $container-lg-min-width) {
        display: none;
    }

    .column {
        display: flex;
        justify-content: center;

        .container {
            position: relative;
            cursor: pointer;

            .icon {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                margin-right: -1.25rem;

                &.active {
                    display: block;
                }
            }
        }
    }

    .dateOrder {
        @extend .column;
        justify-content: left;
        min-width: $fixture-column-date-width;
        width: $fixture-column-date-width;
    }

    .nameOrder {
        @extend .column;
        min-width: $fixture-column-fixture-width;
        width: $fixture-column-fixture-width;
        display: flex;
        justify-content: center;
    }

    .statusesOrder {
        @extend .column;
        min-width: $fixture-column-state-width;
        width: $fixture-column-state-width;
    }
}
