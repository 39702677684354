@import "../../assets/scss/variables.scss";

$height: 32px;

.userRole {
    font-family: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0.375rem;
    text-transform: uppercase;
    word-wrap: break-word;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    white-space: nowrap;
    border: 0;
    border-radius: 1.25em;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    transition: all 0.2s ease-in-out;
    padding: 0.4rem 1.25rem;
    font-size: 0.81rem;
    color: $white;
    background: hsla(0, 0%, 0%, 0.4);

    cursor: pointer;
    &.admin {
        background: $green;
    }

    &.customerAdmin {
        background: $customer-admin;
    }

    &.support {
        background: $support;
    }
}
