@import "../../../assets/scss/variables.scss";

.fileInput {
    display: none;
}

.dropZone {
    width: 100%;
    height: 7rem;
    color: $green;
    border-radius: 0.5rem;
    border: 2px dashed $green;
    cursor: pointer;

    .content {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        label {
            cursor: inherit;
        }

        .icon {
            padding: 1rem;
        }
    }

    &.active {
        border-style: solid;

        .content {
            opacity: 40%;
        }
    }
}
