@import "../../assets/scss/variables.scss";

.fixtureFiles {
    min-width: $player-side-bar-max-width;

    @media only screen and (max-width: $container-md-min-width) {
        width: 100%;
    }

    .title {
        padding-bottom: 1rem;
    }
}
