@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

.parentContainer {
    min-height: $player-description-height;
    height: $player-description-height;
    margin-top: 0.5rem;

    .childContainer {
        display: flex;
        justify-content: flex-start;
        line-height: 2.5;

        .button:last-child {
            margin-right: 0;
        }
    }
}
