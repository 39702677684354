@import "../../../assets/scss/variables.scss";

.parentContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom right, $white, $white);
}
