@import "../../../assets/scss/variables.scss";

.syncButton {
    display: flex;
    box-shadow: $soft-shadow;
    margin: 0.375rem;

    .button {
        margin: 0;
        box-shadow: none;

        &:not(:first-child) {
            border-left-width: 0 !important;
            padding: 0.5rem 1.14rem;
        }
    }
}
