@import "../../../assets/scss/variables.scss";

$modal-border: 1px solid #cccccc;
$modal-max-width: 35rem;
$modal-height: 19rem;
$modal-content-height: 14rem;

$icon-size: 3.5rem;
$icon-box-shadow: 0 0 2px $black;
$icon-bottom-margin: 1rem;

.fileInput {
    display: none;
}

.modal {
    width: 90%;
    max-width: $modal-max-width;
    height: $modal-height;
    background: $white;
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow: visible;
    outline: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: $modal-border;
    border-radius: 0.25rem;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: $modal-content-height;

        .subtitle {
            font-size: 1rem;
            text-align: center;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
        }

        .icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: $icon-size;
            height: $icon-size;
            border-radius: $icon-size;
            box-shadow: $soft-shadow;
            background: $green;
            color: $white;
            margin-bottom: $icon-bottom-margin;
        }
    }

    .confirmParentContainer {
        display: flex;
        flex: 1 1 auto;
        justify-content: flex-end;
        background: $light-black;
        padding: 0 2rem;

        .confirmChildContainer {
            display: flex;
            align-items: center;

            .cancelButton {
                margin-right: 1rem !important;
                background: $white !important;
            }
        }
    }
}
