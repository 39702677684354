@use "sass:math";
@import "../../../assets/scss/variables.scss";

$shadow: 0 25px 15px 1px #00000052;

.parentContainer {
    height: 100%;
    width: 100%;
    position: relative;
    align-items: center;
    display: flex;
    justify-content: center;

    /* Background */
    &:before {
        background-image: url(../../../assets/images/common/atomsBackground.svg);
        opacity: 0.2;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
    }
}

.childContainer {
    background-color: $white;
    background-image: url(../../../assets/images/common/atoms.svg);
    background-repeat: no-repeat;
    background-position: -10.5rem;
    width: $login-size;
    height: calc(($login-size) / 1.7);
    max-width: 865px;
    max-height: 508px;
    min-width: 750px;
    min-height: 441px;
    margin-bottom: 2rem;
    border-radius: 1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;

    -moz-box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
    box-shadow: $shadow;

    .logo {
        display: none;
        margin-top: 5rem;
    }

    @media only screen and (max-width: $container-md-min-width) {
        min-width: $container-sm-min-width;
        background-image: none;
        border-radius: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        align-items: center;
        min-width: 100%;
        max-height: 100%;

        .logo {
            display: block;
            margin-top: 3rem;
        }
    }
}
