@import "../../../assets/scss/variables.scss";

.fixturePageParentContainer {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;

    &:before {
        background-image: linear-gradient(to bottom right, $white, $white-fade);
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        content: "";
    }
}

.fixturePageChildContainer {
    flex: auto;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
    height: 100%;
}
