@import "../../assets/scss/variables.scss";
@import "../../assets/scss/mixins.scss";

$shadow: 2px 0 11px #151738;

.competitionsHeaderContainer {
    width: 100%;
    min-height: 3.5rem;
    height: 3.5rem;
    min-width: 0;
    max-width: 1300px;
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-bottom: 1px solid #efefef;
    white-space: nowrap;
    color: $font-color-soft;

    .column {
        display: flex;
        justify-content: center;

        .container {
            display: flex;
            width: 100%;
            position: relative;

            @media only screen and (max-width: $container-md-min-width) {
                text-align: center;
            }

            .icon {
                display: none;
                position: absolute;
                top: 0;
                right: 0;
                margin-right: -1.25rem;

                &.active {
                    display: block;
                }
            }

            .label {
                @include overflow-ellipsis;
                font-size: 1rem;

                @media only screen and (max-width: $container-md-min-width) {
                    font-size: 0.7rem;
                }
            }
        }
    }

    .leagueOrder {
        @extend .column;
        justify-content: center;
        min-width: $dashboard-column-league-width;
        width: $dashboard-column-league-width;

        @media only screen and (max-width: $container-md-min-width) {
            min-width: 0;
            width: 0;
            overflow: hidden;
        }
    }

    .availableOrder {
        @extend .column;
        justify-content: center;
        min-width: $dashboard-header-state-width;
        width: $dashboard-header-state-width;

        .container {
            justify-content: center;
        }

        @media only screen and (max-width: $container-md-min-width) {
            min-width: $dashboard-column-state-width;
            width: $dashboard-column-state-width;
        }
    }

    .expectedOrder {
        @extend .column;
        justify-content: center;
        min-width: $dashboard-header-state-width;
        width: $dashboard-header-state-width;

        .container {
            justify-content: center;
        }

        @media only screen and (max-width: $container-md-min-width) {
            min-width: $dashboard-column-state-width;
            width: $dashboard-column-state-width;
        }
    }

    .notexpectedOrder {
        @extend .column;
        justify-content: center;
        min-width: $dashboard-header-state-width;
        width: $dashboard-header-state-width;

        .container {
            justify-content: center;
        }

        @media only screen and (max-width: $container-md-min-width) {
            min-width: $dashboard-column-state-width;
            width: $dashboard-column-state-width;
        }
    }

    .markupOrder {
        @extend .column;
        justify-content: center;
        min-width: $dashboard-header-state-width;
        width: $dashboard-header-state-width;

        .container {
            justify-content: center;
        }

        @media only screen and (max-width: $container-md-min-width) {
            min-width: $dashboard-column-state-width;
            width: $dashboard-column-state-width;
        }
    }

    .errorOrder {
        @extend .column;
        overflow: visible;
        justify-content: center;
        min-width: $dashboard-header-state-width;
        width: $dashboard-header-state-width;

        .container {
            justify-content: center;
        }

        @media only screen and (max-width: $container-md-min-width) {
            min-width: $dashboard-column-state-width;
            width: $dashboard-column-state-width;
        }
    }
}
