@import "../common/nav/nav.module.scss";
@import "../../assets/scss/mixins.scss";

.header {
    @extend .nav;
    position: inherit;
    justify-content: space-between;

    @media only screen and (max-width: $container-md-min-width) {
        justify-content: center;
    }

    .button {
        color: $white;
    }

    .title {
        display: flex;
        flex: 1 1 auto;
        justify-content: center;
        font-size: 1.2rem;
        @include overflow-ellipsis;
    }
}

.mobileOuterDiv {
    padding-top: 0 !important;
    display: flex;
    justify-content: space-between;

    width: 100vw !important;
    max-width: 100vw !important;
    min-width: 250px;
    box-sizing: border-box;

    min-height: $nav-height;
    height: $nav-height;
    align-items: center;

    font-size: 0.8rem;
    user-select: none;
    background-color: $dark-green;
    box-shadow: 0 2px $light-black;
    color: $white;
}

.mobileButton{
    flex: 0 0 auto;
    width: 10px;
    color: $white !important;
    max-width: 10px;
}

.mobileTitle {
    flex: 1;
    width: 100px;
    max-width: 400px;
    font-size: 1.2rem;
    margin: 0 10px;
    @include overflow-ellipsis;
    text-align: center;
}